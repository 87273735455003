.editor-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Stack toolbar on top */
}

.custom-quill-editor .ql-toolbar {
  position: absolute;
  top: 0; /* Move toolbar to the top */
  width: 100%;
  z-index: 2;
  background-color: white;
  position: relative;
}

.custom-quill-editor .ql-toolbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  border-bottom: 1px solid #ccc !important; /* Optional: Add border below the toolbar */
}

.custom-quill-editor .ql-container {
  flex-grow: 1;
  margin-top: 0px; /* Add margin to account for toolbar height */
  overflow-y: auto;
  height: calc(100% - 42px); /* Adjust height to exclude toolbar height */
}

