body {
  overflow-x: hidden;
  background-color: #f2f2f2 !important;
}

body p{
  color:rgb(51, 51, 51);
}

body ul {
  list-style: disc;
}

html {
  scroll-behavior: smooth;
}

.container-new {
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

.banner-pt{
  padding-top: 173px !important;
}

/* global styles */
.dark-blue-bg {
  background: #EDEEF1;
}

.bg-white {
  background: #fff;
}

.login-footer-link:hover{
  text-decoration: none;
}


.darkblue-bg{
  background: linear-gradient(to right, #101657, #00164b, #00153e, #001231, #000d23);
}

/* .gray-bg{
  background: rgb(249, 249, 251);
} */

.light-purple-bg {
  background: linear-gradient(55deg, #eaeaf9 100%, #f5f6fc 0%);
}

/* Header Navigation  */

header {
  padding: 0 15px;
}

header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  /* background-color: white; */
  transition: all 0.3s ease-in-out;
  background: transparent !important;
  width: 100%;
}

header.bg-active {
  /* background: linear-gradient(to right, #101657, #00164b, #00153e, #001231, #000d23); */
  background-color: #0d1655b8;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* .header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
  transition: all 0.3s ease-in-out;
  background: transparent;
  box-shadow: 0 7px 25px 0 #e7eef1;
} */



.hamburger-menu {
  display: none;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navigation {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-logo img {
  width: 100%;
}

.site-logo {
  display: inline-block;
  max-width: 210px;
}

.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.navigation ul li>span {
  text-decoration: none;
  color:#fff;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.navigation ul.dropdown-content li a {
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 8px;
  position: relative;
  color:#000D23;
  font-size: 14px;
  line-height: 18px;
}

.navigation ul.dropdown-content li a:hover{
  color:#101657
}

.navigation > ul > li:hover span{
  color:#BAD8FF;
}

.navigation ul.dropdown-content li {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.nav-login {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.mob-nav {
  padding: 0 0 24px 16px;
}

.mob-nav ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.mob-nav ul li:not(:last-child) {
  margin-bottom: 16px;
}

.navigation>ul>li:not(:last-child) span {
  padding-bottom: 5px;
  position: relative;
}

.navigation>ul>li span:after {
  content: "";
  width: 100%;
  height: 1.5px;
  background: #023AF3;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scale(0);
  transition: 0.3s all;
}

.navigation>ul>li:hover>a:after .navigation>ul>li:hover span:after {
  transform: scale(1);
}

.navigation>ul>li {
  position: relative;
  margin: 0 15px;
}

.navigation>ul>li:nth-child(4),
.navigation>ul>li:nth-child(5) {
  margin: 0 10px
}

.navigation>ul>li:nth-child(4) {
  margin-left: 20px;
}

.navigation ul.dropdown-content {
  display: none;
  position: absolute;
  padding: 20px 20px 9px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.1);
  top: 62px;
  min-width: 208px;
  z-index: 5;
  left: -50px;
  opacity: 0;
  border: 1px solid #d7d7d7;
}

.navigation ul li:hover>ul {
  display: block;
  opacity: 1;
}

.navigation ul.dropdown-content:before {
  width: 100%;
  content: "";
  height: 58px;
  background: transparent;
  position: absolute;
  left: 0;
  top: -43px;
}

.mob-nav-dropdown {
  display: block;
}

.dropdown-link {
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  color: #172084;
  font-family: "Montserrat";
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.dropdown-link svg {
  margin-left: 5px;
}

.dropdown-link:hover {
  color:#005CD0;
}

.dropdown-link.svg-rotate svg {
  transform: rotate(180deg);
  transform: 0.3s all ease-in-out;
}

.mob-nav ul li ul {
  gap: 0;
  padding: 10px 7px 7px;
}

.mob-nav ul li ul li a {
  padding: 10px;
  display: block;
  color: #172084;
  text-decoration: none;
  border-bottom: 1px solid #023af34a;
  text-transform: capitalize;
}

.mob-nav ul li ul li a:hover{
  color:#005CD1;
}

.mob-nav ul li ul li a:hover {
  border-color: #000D23;
}

header ul li a.nav-login {
  font-size: 14px;
  transition: 0.3s all;
  text-decoration: none;
  font-weight: 500;
}

.navigation .demo-btn>a{
  background: rgb(0, 99, 198);
  padding: 12px 20px;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  transition: 0.3s all;
  text-decoration: none;
  font-weight: 700;
}

header ul li a.nav-login svg {
  width: 29px;
  height: 31px;
}

.navigation ul.dropdown-content li a:after{
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 2px;
  background: #101657;
  bottom: 6px;
  transform: scale(0);
  transition: 0.3s all
 }

 .navigation ul.dropdown-content li a:hover:after{
  transform: scale(1)
 }

/* mob-nav */
.mob-nav ul {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.mob-nav ul li a {
  transition: 0.3s all;
}

.dropdown-link span {
  display: flex;
  align-items: center
}

.mob-nav .demo-btn {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 0;
  max-width: 158px;
  text-align: center;
  margin-top: 20px;
}

.mob-nav .demo-btn>a {
  width: 100%;
}

.mob-nav .demo-btn a.nav-login:hover {
  color:#05214B
}

.mob-nav a.nav-login:hover svg path{
  fill:#05214B
 }

/* Hero Section  */
.hero-main {
  padding: 173px 20px 140px
}

.hero-img {
  max-width: 721px;
  background: url(../../../assets/images/3.png);
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.hero-img img {
  width: 100%;
}

.widget-1 {
  position: absolute;
  animation: 10s slideY-new linear infinite;
  width: 205px;
  bottom: 32%;
  right: 26%;
}

.widget-2 {
  position: absolute;
  top: 3%;
  animation: 10s slideY-new linear infinite;
  width: 211px;
  right: 26%;
}

.hero-content h1 {
  font-weight:400;
  font-size: 50px;
  line-height: 1.3em;
  color: #fff;
  padding: 0;
  margin: 0;
  max-width: 626px;
}


.hero-content span {
  display: inline-block;
  font-weight: 700;
}

.hero-content p {
  font-size: 16px;
  color: #fff;
  padding: 0;
  margin: 0;
  line-height: 1.6em;
  margin: 20px 0;
  max-width: 604px;
}

.hero-btns{
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.hero-btns a {
  border-radius: 8px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  padding: 13px 21px;
  text-decoration: none;
  transition: all 0.3s;
  color: #183157;
  background-color: #F2F2F2;
  transition: 0.3s all;
  border: 1px solid #F2F2F2;
}

.hero-btns a:hover {
  background-color:#005CD1;
  color: white;
  transform: scale(1.05);
  border-color: #005CD1;
}

.hero-btns a.outline-btn {
  background: transparent;
  border-color: #F2F2F2;
  color: #F2F2F2;
}


.hero-btns a.outline-btn:hover{
  background: #F2F2F2;
  color: #183157;
}


/* Logo Slider  */
.slider-logo {
  padding: 45px 15px ;

}

.slider-logo-wrap {
  position: relative;
}

.slider-logo h3 {
  letter-spacing: 0.16em;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b3b3b3;
  margin: 0 0 40px;
  text-align: center;
  text-transform: uppercase;
}

/* .slider-logo-wrap:after,
.slider-logo-wrap:before {
  width: 108px;
  height: 100%;
  right: 0;
  background: linear-gradient(-90deg,
      rgb(255 255 255) 0%,
      #ffffff 12.72%,
      #ffffffc2 87.58%,
      rgb(217 217 217 / 0%) 100%);
  position: absolute;
  content: "";
  top: -1px;
  z-index: 1;
} */

.slider-logo-wrap:before {
  left: 0;
  right: auto;
  transform: rotate(-180deg);

}

.slider-logo img {
  max-width: 50%;
  filter: contrast(50%);
}

/* About Section */
.about-new {
  padding: 65px 0 ;
}

.about-new.bg-overlay:after {
  width: 649px;
}

.about-new-section {
  padding: 0 16px;
}

.about-new-section .about-sec-txt {
  text-align: center;
  font-size: 15px;
  line-height: 1.6em;
  margin: 0;
  color: #111653;
  text-align: center;
}

 .about-tagline {
  letter-spacing: 0.02em;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2em;
  text-align: center;
  color: #091E42;
  margin: 1rem 0 1rem 0;
  text-align: center;
}

.about-tagline span{
  color: #005CD1;
}

 .about-title {
  color: #091E42;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
  text-align: center;
  text-transform: uppercase;
}

.about-cards-section {
  /* display: flex;
  justify-content: space-between; */
  padding-top: 42px;
  gap: 30px;
}

.about-card h3 {
  font-size: 22px;
  color: #091E42;
  letter-spacing: normal;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  padding: 0;
  margin: 0;
}

.about-card p {
  font-size: 14px;
  color: #091E42;
  padding: 0;
  margin: 0;
}

.about-card {
  align-items: center;
  border-radius: 28px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  padding: 30px 38px 21px;
  text-align: center;
  width: calc(33.33% - 15px);
  border:1px solid #a7a4a452;
  background: #fff;
}

.about-new-section .slick-slide{
  padding: 0 10px 25px;
}

.about-card img {
  width: 100%;
  max-width: 245px;
}

.slick-dots li button:before{
  color:#05214B !important
}

.slick-dots li {
width: 10px !important}

.slick-dots li button:before{
  font-size: 11px !important;
}

.slick-dots li.slick-active button:before{
  color:#05214B !important
}

.about-cards-section .about-card:hover {
  box-shadow: 0 10px 15px #0000001a;
}

.about-cards-section .about-card:hover img{
  transform: scale(1.1);
  transition: 0.3s all
 }

 .about-cards-section .about-card img{
  transition: 0.3s all
 }

.about-cards-section .about-card {
  transition: 0.3s all;
}

 .about-card h4{
  text-align: center;
  color: #111653;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
 }

/* .about-new.bg-overlay:after{
  height: 500px;
  top: -30px;
  left: -250px;
  right: auto;
 } */

/* Feature Section  */
.feature-tab-section {
  padding: 75px 30px;
  max-width: 1330px;
}

.inds-tabs-wrap{
  padding: 0 10px;
}

.feature-title {
  text-align: center;
  color: #111653;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}


.feature-tagline {
  letter-spacing: 0.02em;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2em;
  text-align: center;
  color: #05214B;
  margin: 1rem 0 1rem 0;
}

.feature-txt-new {
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
  color: #183157;
  max-width: 872px;
  margin: 0 auto;
}

.featrure-tabs-buttons {
  grid-gap: 2.5rem;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2.5rem 0 3.5rem;
}

.featrure-tabs-buttons button {
  color: #05214B;
  border-radius: 9px;
  font-family: "Montserrat";
  outline: none;
  border: none;
  padding: 16px 28px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
  border: 1px solid #05214B;
  background: #F6F8F9;
}

.featrure-tabs-buttons button:hover{
  color: #ffffff;
  background-color: #05214B;
  transition: 0.25s all ease-in-out;
  border-color: #05214B;
}

 .featrure-tabs-buttons .active-tab {
  border-color: #005CD1;
  background-color: #005CD1;
  color: #fff;
}

.inner-feature-container {
  border-radius: 28px;
 background: white;
  padding: 61px 30px 23px;
  border: 1px solid #a0a9b791;
}

.reportingSection .featrure-tabs-buttons button:not(.active-tab){
  background: #88cbfc26;
}

.reportingSection .featrure-tabs-buttons button.active-tab , 
.reportingSection .featrure-tabs-buttons button:not(.active-tab):hover{
    color: #ffffff;
    background-color: #2f77d3;
}

.ind-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
    row-gap:20px
}

.ind-tabs button {
    width: calc(25% - 20px);
    margin: 0 10px 
}

.inds-tabs-sect .cooridnate-social-content h3{
    margin: 0 0 30px 0;
    max-width: 100%
 }
 
 .inds-tabs-sect .cooridnate-social-content{
    width: 100%;
    text-align: center
 }
 
 .inds-tabs-sect.inner-feature-container{
    padding-bottom: 50px
 }

 .inds-tabs-sect  .cooridnate-social-content p{
  max-width: 100%;
 }

 .inds-tabs .slick-slide img {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
}

.slick-prev:before, .slick-next:before{
  font-size: 30px;
  line-height: 34px;
  color: #05214B;
 }
 

.inds-tabs .slick-next{
  right: -20px
 }
 
 .inds-tabs .slick-prev{
  left: -30px
 }

 .inds-tabs .cooridnate-social-content p{
  font-size: 15px;
  line-height: 1.2;
 }
/* Co-ordinate Section  */
.feature-inner-wrapper {
  max-width: 1050px;
  margin: 0 auto;
}

.cooridnate-social {
  display: flex;
  justify-content: space-between;
}

.cooridnate-img {
  max-width: 480px;
  padding-left: 40px;
  margin-top: -29px;

}

.cooridnate-img img {
  position: relative;
  width: 100%;
  transition: 0.3s all;
}

.cooridnate-img:hover img{
  transform: scale(1.1);
}

.cooridnate-social-content {
  width: calc(100% - 480px);
}

.cooridnate-social-content h4 , .zigzag h4{
  color: #111653;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}

.cooridnate-social-content h3 , .zigzag h3 {
  letter-spacing: 0.02em;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.3em;
  color: #091E42;
  margin: 20px 0;
  max-width: 490px;
}

.cooridnate-social-content ul {
  padding: 0;
  margin: 0 0 0 1rem;
}

.cooridnate-social-content ul li , .cooridnate-social-content p , .zigzag p{
  font-size: 17px;
  line-height: 1.3;
  color: #111653;
  max-width: 500px;
}

.cooridnate-social-content ul li::marker {
  color: #023AF3;
}

.danger {
  font-size: 14px;
  margin: 10px 0 0 10px;
  line-height: 1.1;
}

.cooridnate-social-content a , .blue-btn , .white-btn{
  border: 1px solid #005CD1;
  border-radius: 9px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-top: 30px;
  padding: 16px 28px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  color: #005CD1;
  width: max-content;
}

.white-btn{
  border-color: #fff;
  color: #fff;
}

.white-btn:hover{
  background: #091E42;
  border-color: #091E42;
}

.cooridnate-social-content a{
  margin: 20px auto 0;
}

.cooridnate-social-content .discover{
  color: white;
  background-color: #111653;
  border-color: #111653;
}

.cooridnate-social-content .discover:hover , .blue-btn:hover {
  background-color: #005CD1;
  border-color: #005CD1;
  color: white;
}

.cooridnate-social-content .request {
  color: #005CD1;
  background-color: transparent;
}

.cooridnate-social-content .request:hover {
  background-color: #111653;
  color: white;
  border-color: #111653;
}

/* Contact Form Section  */

.contact-section {
  align-items: center;
  display: flex;
  padding: 90px 20px 90px;
  max-width: 1170px;
}

.contact-slider {
  width: calc(100% - 500px);
  padding-left: 50px;
}

.contact-form-new {
  max-width: 500px;
  width: 100%;
}

.contact-form-new form {
  max-width: 514px;
}

.contact-slider-img {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.contact-slider-img img {
  max-width: 350px;
  width: 100%;
}

.contact-form-new h5 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.2em;
  color: #091E42;
  margin: 20px 0 40px;
}

.contact-form-new h4 {
  color: #091E42;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}

.form-label-new {
  font-weight: bold;
  color: #091E42;
  display: inline-block;
  padding: 0 0 7px 9px;
}

.contact-form {
  margin-bottom: 0.5rem;
}

.form-control-new {
  margin-bottom: 20px;
}

.contact-form-new input,
.contact-form-new textarea {
  color: #111653 !important;
  padding-bottom: 1rem !important;
  padding: 18px !important;
  border: none;
  background-color: white;
  width: 100%;
  border-radius: 12px;
  font-family: "Montserrat";
  border:1px solid #11165359;
  outline: transparent;
}

.contact-form-new textarea {
  resize: none;
}

.contact-form-new input:focus,
.contact-form-new textarea:focus,
.contact-form-new input:hover,
.contact-form-new textarea:hover {
  border: 1px solid #023AF3;
  outline: #023AF3;
  background-color: #ffffff73;
}

.contact-form-new input::placeholder,
.contact-form-new textarea::placeholder {
  color:#111653 !important;
  font-weight: 400 !important;
  font-family: "Montserrat";
}

.contact-form-btn {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  border-radius: 12px;
  display: inline-block;
  margin-top: 1.5rem;
  padding: 17px 50px;
  background-color: #005CD1;
  transition: 0.25s all ease-in-out;
  font-family: "Montserrat";
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
}

.contact-form-btn:hover {
  background-color: #05214B;
}

.contact-slider-content {
  text-align: center;
}

.contact-slider-content h4 {
  color: #0063c6;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}

.contact-slider-content h3 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 29px;
  line-height: 1.2em;
  color: #0063c6;
  max-width: 467px;
  margin: 1.5rem auto;
}

.slider-content-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-slider-content ul {
  color: #0063c6;
  margin: 0 0 0 1rem;
  padding: 0;
  text-align: left;
}

.contact-slider-content ul li {
  color: #0063c6;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.contact-slider-content a {
  border: 1px solid #0063c6;
  border-radius: 9px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 13px 15px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.contact-slider-content .discover {
  color: white;
  background-color: #0063c6;
  margin-right: 20px;
  border: 1px solid #0063c6;
}

.contact-slider-content .discover:hover {
  background-color: #18509e;
}

.contact-slider-content .request {
  color: #0063c6;
  background-color: transparent;
}

.contact-slider-content .request:hover {
  background-color: #0063c6;
  color: white;
}

/* CTA Section  */

.cta-section:not(.api-section){
  background: #005CD1;
}
.cta-new-section {
  max-width: 1000px;
  margin: 0 auto;
}

.cta-new-section img{
  width: 240px;
}

.api-section .cta-new-section img{
  margin: -40px 0
}

.cta-new-section h4 {
  color: #fff;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.cta-new-section h3 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4em;
  color: #fff;
  margin: 1rem 0 1rem 0;
}

.border-btn {
  border: 1px solid #fff;
  border-radius: 9px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-top: 40px;
  padding: 16px 28px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.cta-new-section a.border-btn {
  color: #fff;
  border-color: #fff
}

.cta-new-section a.border-btn:hover {
  box-shadow: 0 10px 28px 10px rgb(255 255 255 / 34%);
}

.cta-new-section p {
  font-size: 15px;
  line-height: 1.6em;
  color: #fff;
  margin-top: 30px;
  max-width: 783px;
}

.cta-section{
  padding: 30px 20px 20px;
}

.template-sect{
  padding: 50px 20px ;
}
.cta-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.api-section .cta-wrapper{
  max-width: 1250px;
  background: linear-gradient(to right, #101657, #00164b, #00153e, #001231, #000d23);
  padding:70px 40px;
  border-radius: 30px
 }
 
 .api-section .cta-new-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 30px
 }
 
 .api-section .cta-new-section .box{
  width:100%;
 }

 .api-section p{
  max-width: 600px
 }


/* Definitions Section */

.definitions-section {
  padding: 50px 20px;
}

.definitions-section .container-new {
  max-width: 1000px;
}

.definitions-section h3 {
  color:#091E42;
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.08em;
  margin: 0rem 0 1.5rem 0;
  text-align: center;
}

.def-txt p {
  color: #091E42;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0 !important;
  margin-bottom: 20px;
}

.def-txt:not(.who-we-center) p:last-child {
  margin: 0;
}

.def-txt span {
  color: #091E42;
  font-weight: 600;
  display: inline-block;
  margin: 0 5px;
}

.def-txt h4 {
  color: #091E42;
  font-weight: 600;
  margin: 0 0 10px
}

.def-txt ul li {
  color: #091E42;
  font-size: 14px;
  line-height: 20px;
}

.def-txt ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

/* Priamry Pages Hero Section  */

.zig-hero-wrap {
  padding: 145px 30px 50px;
}

.bg-overlay , .overlay-right , footer , .bg-overlay-dark{
  position: relative;
  overflow: hidden;
}

/* .bg-overlay:after,.overlay-right:after, .bg-overlay-dark:after ,
footer:after {
  position: absolute;
  background: url(../../../assets/images/bg-overlay-dark.png) no-repeat top right;
  height: calc(100% + 98px);
  content: "";
  top: 0;
  background-size: contain;
  top:-25px;
  width: 584px;
  left: -173px;
  opacity: 0.04;
  z-index: 1;
} */

/* 
footer:after {
  position: absolute;
  background: url(../../../assets/images/bg-overlay-dark.png) no-repeat top right;
  height: calc(100% + 98px);
  content: "";
  top: 0;
  background-size: contain;
  top:-25px;
  width: 326px;
  left: -173px;
  opacity: 0.4;
  z-index: 1;
} */

.overlay-right:after{
  right: -100px;
  left: auto;
}

/* .bg-overlay-dark:after{
  background: url(../../../assets/images/bg-overlay-dark.png) no-repeat top right;
  background-size: contain;
  opacity: 0.03;
  right: -130px;
} */

.animate-section.overlay-right:after{
  z-index: 2;
}

footer:after{
  height: calc(100% + 157px);
  top: 0;
  left: -74px;
}

.zig-hero-wrap .container-new {
  max-width: 1160px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.zig-hero-img {
  max-width: 500px;
}

.zig-hero-content {
  width: calc(100% - 515px);
}

.zig-hero-img {
  text-align: right;
  transition: 0.3s all;
}

.zig-hero-img:hover{
  transform: scale(1.05)
 }

.zig-hero-img img {
  width: 100%;
  padding: 30px 0;
  max-width: 95%;
}

.zig-hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.zig-hero-content h3 {
  color:#091E42;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0;
  word-wrap: break-word;
  max-width: 545px;
}

.zig-hero-content h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-top: 0;
  text-transform: uppercase;
  color: #091E42;
  letter-spacing: 0.16em;
}

.zig-hero-content p {
  font-size: 15px;
  line-height: 1.6em;
  color: #091E42;
  margin: 25px 0 0;
  max-width: 485px;
}

/* .zig-hero-content a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 9px;
  border: 1px solid #fff;
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  transition: 0.25s all ease-in-out;
  background-color: transparent;
  text-align: center;
  max-width: 200px;
}

.zig-hero-content a:hover {
  background-color: #fff;
  color: #0063c6;
} */

/* secondary pages hero */

.sec-hero {
  padding: 70px 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sec-hero h3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-top: 0;
  letter-spacing: 0.16em;
}

.sec-hero h2 {
  font-weight: 400;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0
}

.sec-hero p {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 26px
}

.sec-hero h2,
.sec-hero h3,
.sec-hero p {
  text-align: center;
  color:#091E42;
}

.sec-hero .container-new {
  max-width: 846px
}


/* Request Demo Section  */

.request-demo-section {
  padding: 50px 16px;
  text-align: center;
  color: white;
}

.request-demo-section h3 {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.request-demo-section p {
  max-width: 947.73px;
  margin: 0 auto;
  font-size: 15px;
  padding: 0;
  line-height: 1.6rem;
}

/* Footer Section  */

.footer-flex {
  display: flex;
  justify-content: space-between;
  padding: 75px 16px;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer-logo-new {
  display: block;
  width: 210px;
  margin-top: -10px;
}

.footer-logo-new img {
  width: 100%;
}

.footer-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-links h4 {
  font-size: 15px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  margin-top: 0;
  margin-bottom: 15px;
}

.footer-links ul li a {
  font-size: 11px;
  font-weight: 600;
  color: white;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  letter-spacing: 0.16em;
  transition: 0.3s all;
}

.footer-links ul li a:hover {
  transform: scale(1.1);
}

.footer-social {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.footer-social p {
  font-size: 11px;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  padding: 0;
  margin: 0;
}

.socials span {
  font-size: 11px;
  letter-spacing: 0.16em;
  font-weight: bold;
  color: white;
}

.social-links li a:hover {
  transform: scale(1.1);
}

.footer-flex {
  position: relative;
  z-index: 2;
}

.footer-flex>div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
}

.footer-flex .footer-links{
  flex: 1.5
 }

.desk-hide {
  display: none;
}


/* Animated Section  */

.animate-section {
  padding: 60px 20px ;
  position: relative;
  overflow: hidden;
}

.animate-section .container-new {
  max-width: 1272px;
}

.animt-wrap {
  max-width: 1060px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choose-socialpro {
  padding-bottom: 7px;
}

.choose-socialpro h2 {
  color: #fff;
  font-size: 34px;
  line-height: 35px;
  margin: 0;
  font-weight: 400;
  text-align: center;
}

.choose-socialpro h2 span{
  font-weight: 600;
}

.animated-heading {
  width: 553px;
  padding-left: 60px;
  position: relative;
  padding: 30px 0 10px 15px;
}

.animated-heading:before {
  bottom: 20px;
  transform: rotate(180deg);
  top: auto;
  height: 59px;
}

.animated-heading h5 {
  font-size: 34px;
  line-height: 34px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 23px;
  color: #fff;
  opacity: 0.4;
  font-weight: 400 !important;
}

.animated-heading .slick-current h5 {
  opacity: 1;
  font-weight: bold !important;
}

.animated-heading .slick-slide {
  border: 0;
}

/* Slick Slider Settings  */
.contact-slider .slick-dots {
  position: static;
}

.contact-slider .slick-dots li,
.contact-slider .slick-dots li button {
  height: 10px;
  width: 19px;
  transition: 0.2s all linear;
}

.contact-slider .slick-dots li {
  margin: 0 3px;
}

.contact-slider .slick-dots li button {
  background: #0064c4;
  border: 0;
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
  outline: none;
  border-radius: 4px;
  padding: 3px;
}

.contact-slider .slick-dots li.slick-active button,
.contact-slider .slick-dots li.slick-active {
  width: 40px;
}

.contact-slider .slick-dots li button:before {
  opacity: 0 !important;
}

.contact-slider .slick-dots li.slick-active button {
  background: #00aaff;
}

.contact-slider .slick-slide {
  padding-bottom: 20px;
}

.contact-slider .slick-dots {
  bottom: 0;
}

.loginform h2{
  text-align: center;
  margin-bottom: 30px;
}

.loginform input{
  color: #111653 !important;
     padding-bottom: 1rem !important;
     padding: 15px !important;
     border: none;
     background-color: white;
     width: 100%;
     border-radius: 12px;
     font-family: "Roboto", sans-serif;
     outline: transparent;
 }


 .loginform  fieldset.Mui-error {
  border-radius: 12px !important
 }

 /* .loginform form label{
  display: none;
 } */
 

/* new-form-section for homepage */

.forms-section.bg-overlay:after {
  left: auto;
  right: -56px;
  background-position: left center
}

.forms-section .container-new {
  display: flex;
}

.forms-section {
  padding: 75px 20px;
}

.col-left {
  width: calc(100% - 500px);
  padding-right: 30px;
}

.col-right {
  max-width: 500px;
  width: 100%;
}

.col-left h4 {
  text-align: left;
}

.forms-section h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.1em;
  color: #091E42;
  padding: 0;
  margin: 20px 0 30px;
  max-width: 504px;
}

.forms-section h2 span {
  color: #005CD1;
}

.forms-section h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3em;
  color: #091E42;
  max-width: 466px;
  margin: 10px 0 15px;
}

.col-left ul {
  margin: 0;
  padding-left: 20px;
}

.col-left ul li {
  font-size: 16px;
  line-height: 1.6em;
  color:#091E42;
  margin-bottom: 10px;
  font-weight: 500;
}

.col-left ul li::marker{
  color: #005CD1;
}
.col-right form,
.col-right .contact-form-new {
  max-width: 100%;
  width: 100%;
}

.col-right .contact-form-btn {
  margin: 0;
}

.hero-img {
  max-width: 852px;
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: -145px;
  top: 83px;
}

.hero-new .container-new {
  position: relative;
  max-width: 1450px;
}

/* others pages styles */
.reportingSection .featrure-tabs-buttons {
  margin-top: 0
}

/*logos style*/

.logo-img img {
  max-width: 150px;
  max-height: 100%;
}

.logos-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: -45px;
}

.logos-wrapper h3 {
  text-align: center;
  color: #b3b3b3;
  margin: 0 0 65px;
  letter-spacing: 0.16em;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
}

.logos-wrap .logo-img {
  width: calc(25% - 20px);
  display: flex;
  justify-content: center;
  padding: 0;
  min-height: 72px;
  height: 82px;
  align-items: center;
  transition: 0.3s all;
  margin: 0 10px 45px;
}

.logos-wrapper {
  padding: 70px 15px
}

/* accordion style */
.accordion-wrap {
  padding: 70px 20px;
}

.accordion-wrap .container-new {
  max-width: 900px
}

.accordion-item {
  margin-bottom: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #005cd182;
  background: transparent;
}

.accordion-wrap .Mui-expanded {
  margin: 0;
}

.accordion-item:before {
  display: none
}

.accordion-title {
  padding: 15px 20px;
}

.accordion-title>div {
  font-size: 16px;
  color: #05214B;
  margin: 0;
  line-height: 28px;
  font-weight: 700;
}

.accordion-title .MuiAccordionSummary-content {
  padding-right: 20px;
  margin: 0;
}

.accordion-wrap .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.accordion-content {
  padding: 0 20px 20px
}

.accordion-content p {
  color: #05214B;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.accordion-content ul {
  padding-left: 20px;
}

.accordion-content ul li {
  color: #05214B;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.accordion-content ul li ul {
  list-style: disc;
  margin: 14px 0 20px
}

.accordion-section {
  padding: 70px 20px
}

.accordion-section .container-new {
  max-width: 1000px;
}

.accordion-section .accordion-wrap {
  padding: 0;
}

.support-section .accordion-wrap {
  margin-top: 40px;
}

.accordion-section h2 {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0;
  color: 091E42;
  text-align: center;
}

.def-txt img{
    max-width: 150px;
    margin: 35px auto 20px;
    display: block;
 }

 .def-txt.who-we-are p{
    text-align: center
 }
 
 .who-we-center p{
     margin: 0 auto;
     max-width: 852px;
 }

  .blog-detail .text ul li , .blog-detail .text ol li{
    color: #091E42;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 5px
  }

  .blog-detail .text ul  , .blog-detail .text ol {
    margin: 20px 0
  }

  .blog-detail .text p span{
    display: block;
    font-weight: bold
   }
/* cards-section(implementation page ) */
.cards-wrap {
  display: flex;
  flex-wrap: wrap;
}

.container-new.compress-container{
 max-width: 1150px;
}

.cards-wrap .card-box {
  padding: 30px 20px;
  border-radius: 5px;
  width: calc(25% - 20px);
  margin: 0 10px 20px;
  transition: 0.3s all;
  /* background: linear-gradient(55deg, #fff 1%, #a5daff); */
  background:white;
  border:1px solid #a0a9b791
}

.cards-wrap .card-box h4 {
  color:#091E42;
  font-size: 18px;
  line-height: 26px;
  margin: 12px 0
}

.cards-wrap .card-box h5 {
  color: #091E42;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 400;
}

.cards-wrap .card-box p {
  color: #091E42;
  font-size: 14px;
  margin: 0;
}

.cards-wrapper {
  padding: 70px 20px 50px
}

.cards-wrap .card-box:hover {
  box-shadow:0 10px 15px #0000001a;
}

.who-we-are-wrap.cards-wrapper {
  padding: 45px 0 0
}

.who-we-are-wrap h3 {
  margin-bottom: 60px
}

.who-we-are-wrap .cards-wrap .card-box , .cards-wrap.who-we-are-wrap .card-box{
  width: calc(33.33% - 20px);
}

 .cards-wrap .card-box img {
  width: 80px;
  margin-bottom: 20px
}

/* question cta */
.question-cta {
  padding: 70px 20px;
  text-align:center;
  overflow: hidden;
}

.question-cta .container-new{
    text-align: center;
    padding: 50px 20px;
    max-width: 1000px;
    margin: 0 auto;
    background: white;
    border:1px solid #EDEEF1;
    border-radius: 40px
 }

.question-cta.bg-overlay:after {
  top: -20px;
  width: 530px;
  right: -180px;
  left: auto;
  opacity: 1;
}

.question-cta h3 {
  color: #091E42;
  font-size: 38px;
  line-height: 1.2;
  margin: 0;
}

.question-cta p {
  color: #091E42;
  font-size: 20px;
  margin: 30px 0 30px
}

a.btn-dark-blue {
  background: #005CD1;
  padding: 16px 20px;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  transition: 0.3s all;
  text-decoration: none;
  font-weight: 700;
  display: inline-block
}

a.btn-dark-blue:hover {
  background: #091E42;
}

.question-cta a.btn-dark-blue {
  padding: 16px 30px;
}

/* Blogs */
.blog-section {
  padding: 70px 20px
}

.blog-section .blog-detail{
  padding: 0 10px
}

.blogs-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
}

.blog {
  width: calc(33.33% - 30px);
  background: #fff;
  border-radius: 10px;
  padding: 0 20px 30px;
  transition: 0.3s all;
  margin: 0 15px 30px;
  box-shadow: 0 6px 18px 1px #cedced;
}

.blog:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2)
}

.blog-img {
  margin: 0 auto;
  overflow: hidden;
  margin: 0 -20px;
  border-radius: 10px 10px 0 0;
}

.blog-img a {
  height: 250px;
  display: block;
}

.blog-img div img {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100% !important
}

.blog-img a:hover img {
  transform: scale(1.3);
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: stretch
}

.blog h4 {
  margin: auto 0 0;
  padding-top: 20px;
  color: #005CD1;
  font-weight: 500;
  font-size: 13px
}

.blog-img img {
  max-width: 100%;
  transition: 0.5s all;
}

.blog h3 {
  margin: 25px 0 0
}

.blog h3 a {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.2em;
  margin: 0;
  color: #05214B;
  text-decoration: none;
}

.blog h3 a:hover {
  text-decoration: underline
}

.blog p {
  margin-bottom: 0;
  color:#05214B;
}

.forms-section .accordion-wrap {
  padding: 0;
}

.blog-detail h1 {
  color: #05214B;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.4;
  margin: 30px 0;
  margin-top: 0
}

.blog-detail h3{
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-top: 0;
  text-transform: uppercase;
  color:#0052CC;
  letter-spacing: 0.16em;
}

.blog-detail h4 {
  font-size: 20px;
  line-height: 30px;
  color: #05214B;
  font-weight: 400
}

.blog-detail .blog-det-img {
  min-height: 232px;
  position: relative;
  padding-bottom: 40%;
  margin: 40px 0 30px;
  display: block
}

.blog-detail .blog-det-img img {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100%;
  left: 0;
  object-fit: cover;

}

.blog-detail .text p {
  color: #05214B;
  font-size: 16px;
  line-height: 1.4;
}

.loginPage .social-link a{
  color:#fff
 }

 .blog-detail .text ol, .blog-detail .text ul{
    padding-left: 22px
}
 
 .blog-socials {
  display: flex;
  align-items: center
 }
 
 .blog-socials span{
  padding-right: 20px;
  color:#0052CC;
  font-weight: 500
 }
 
 .blog-socials a {
  margin: 0 10px  0 0;
  padding: 6px 7px;
  border: 1px solid rgb(103, 110, 126);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px
 }
 
 .blog-socials a:hover{
  background: #ffffff73
 }
 
 .blog-socials a img{
  width: 18px
 }

 header .navigation ul {
  padding: 10px 30px;
  border-radius: 24px;
  border:1px solid #ffffff21;
}

.head-btns {
  display: flex;
  align-items: center;
  gap: 15px;
}

.head-btns .demo-btn  , .demo-btn a.demo-link{
  text-decoration: none;
  background: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  color:#183157;
  transition: 0.3s all;
  font-weight: 600;
}

.demo-btn a.demo-link{
  background-color: #005CD1;
  color: white
}

.demo-btn a.demo-link:hover{
  background: #101657;
}

.head-btns a:not(.nav-login):hover {
  box-shadow: 0 0 56px rgb(93 93 93 / 90%);
  background-color: #005CD1;
  color: white
}

a.nav-login {
  background: transparent;
  border-radius: 0;
  color: white;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
}

.mob-nav a.nav-login {
 color: #BAD8FF;
}

.head-btns a.nav-login:hover {
  color: #BAD8FF
}

.head-btns a.nav-login:hover svg path {
  fill: #BAD8FF
}

.head-btns a.nav-login svg {
  width: 25px;
  height: 25px;
}


.hero-new {
  /* background-image: linear-gradient(to right, #101657, #091E42, #091E42, #001231, #000d23); */
  position: relative;
  background: #fff;
}


.hero-new:after {
  background: url(/src/assets/images/heroBg.png) no-repeat;
  background-position: bottom;
  background-size: cover;
  content: "";
  height: calc(100% + 70px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.blue-bg{
  background-image: linear-gradient(to right, #101657, #091E42, #091E42, #001231, #000d23);
}

/* inds slider */
.inds-tabs .cooridnate-social-content {
  padding: 20px 30px;
  border:1px solid #a0a9b791;
  border-radius: 20px;
  text-align: center;
  height: 100% !important;
  background: #F4F4F4;
 }
 
 .inds-tabs .slick-slide{
  padding: 0 10px;
    transition: transform 0.5s;
  height: auto !important;
  z-index: 2
 }
 
 
 .inds-tabs .slick-slide > div{
   height: 100% !important;
  position: relative !important
 }

 
 .inds-tabs .slick-track {
  display: flex ;
  padding: 100px 0 50px;
 }
 
 .inds-tabs .slick-list {
  padding-bottom: 30px !important
 }
 
 .inds-tabs .slick-slide.slick-center {
    transform: scale(1.17, 1.1);
    position: relative;
    z-index: 99999;
    background: #F4F4F4;
    padding: 20px 12px;
    border-radius: 14px;
    border: 1px solid #e0e2e7;
 }
 
 .inds-tabs .slick-slide.slick-center  .cooridnate-social-content {
    transform: scale(0.90, 0.90);
    padding: 0 !important;
    display: flex !important;
    flex-direction: column;
 }
 
 
 .inds-tabs .slick-track .slick-slide:not(.slick-center) > div:after {
    width:100%;
    height: 130%;
    background: transparent;
    content:"";
    position: absolute;
    z-index: 3;
    top: -20px;
    left: 0;
    backdrop-filter: blur(1.5px)
 }

 
 .inds-tabs .slick-slide.slick-center  .cooridnate-social-content p{
    line-height: 1.2;
    font-size: 15px;
    margin: 10px -3px 4px;
 }
 
 .inds-tabs .slick-slide.slick-center  .cooridnate-social-content {
    border: none;
    padding: 40px 30px 30px
 }

 .inds-tabs h3{
    font-size: 25px;
    line-height: 1.3;
    letter-spacing: 0;
    margin: 5px 0 10px;
    font-weight:600;
 }

  .inds-tabs h3 span{
    display: block;
    font-weight: 700;
    color: #3061AE;
  }

 /* sub-header */
header.sub-header .navigation ul li span,
header.sub-header .navigation ul li a {
  color: #005CD1
}

header.sub-header .head-btns .demo-btn {
  background: #005CD1;
  color: #fff
}

header.sub-header .head-btns .demo-btn:hover {
  box-shadow: none;
  transform: scale(1.1)
}

header.bg-active .sub-head svg path {
  fill: #fff
}

header.sub-header a.nav-login {
  color: #005CD1
}

header.sub-header .head-btns a.nav-login:hover {
  color: #05214B
}

header.sub-header .head-btns a.nav-login:hover svg path {
  fill: #05214B;
}

header.sub-header .navigation ul li:hover span {
  color: #05214B
}

header.sub-header {
  background: rgb(255 255 255 / 90%);
}

.mobile-hero{
  display: none;
}

.animate-section .container-new {
  padding: 30px 40px;
  border-radius: 30px;
 }

 header.sub-header .navigation ul{
  border-color:#EDEEF1 !important;
  background: #fff;
 }

 header.sub-header svg path{
  fill: #005CD1
 }
 
 header.sub-header.active{
  box-shadow: 0 5px 20px #0000002b
 }

 .fast-track .cta-new-section {
  display: flex;
  column-gap: 30px;
  align-items: center
 }
 
 .fast-track .cta-new-section{
  max-width: 100%
 }
 
 .fast-track .cta-new-section img{
   width: 150px
 }
 
 .fast-track .box{
    width: 100%;
    display: flex;
    align-items: center  
 }
 
 .fast-track .content{
  flex:1
 }
 
 .fast-track .content h3{
  margin: 0
 }
 
 .fast-track .content p{
  margin: 10px 0 0
 }
 
 .fast-track .content{
  width: calc(100% - 553px);
 }

 /* counter */
 .counter{
  background: #f4f4f4;
  padding: 60px 50px;
  border-radius: 40px;
  display: flex;
  column-gap: 35px;
  margin-top: 40px
 }
 
 .count-box{
   text-align: center;
  width: calc(25% - 20px)
 }
 .count-box h5{
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0 0;
  color: #091E42
 }
 
 .count-box p{
  margin: 10px 0 0;
  font-size: 13px;
 }
 
 .count-box div.count{
 font-size: 44px;
  font-weight: bold;
  color:#005CD1
 }
 
 .count-box > div{
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: center
 }
 
 .count-box > div > span{
  font-size: 42px;
  color: #005CD1;
  font-weight: bold;
 }
 
 .counter-section{
  padding: 80px 20px;
  background: #fff;
 }

 .template-sect  .cta-wrapper{
  background: #DFE4E8;
  max-width: 1200px;
  padding: 40px 30px;
  cursor: pointer;
    transition: 0.3s all;
 }

 .template-sect .cta-wrapper:hover {
  transform: scale(1.05);
}
 
 .template-sect h3 , .template-sect h4 , .template-sect p{
  color: #091E42
 }

 .fast-track .cta-new-section img {
  width: 105px
}
 
.terms-hero p{
  text-transform: capitalize
 }
 

 .hero-content {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 80px
 }
 
 .hero-text , .hero-image{
  width:50%
 }

 .hero-text{
  padding: 50px 0 0 0;
 }

 .slider-logo-wrap .slick-slide img{
  margin: 0 auto
 }

.get-started .col-left {
  width: calc(100% - 600px);
}

.get-started .col-right {
  max-width: 600px
}



/* Media Queries  */

@media (max-width: 1200px) {
  .hero-img {
    max-width: 650px;
    right: 0;
    top: 90px;
  }

  .widget-2 {
    width: 186px;
  }

  .widget-1 {
    width: 181px;
  }
}

@media (max-width: 1100px) {

  .inds-tabs .cooridnate-social-content{
    background: #fff;
    padding-top: 40px;
  }

  .choose-socialpro h2  {
      font-size: 36px;
  }

  .choose-socialpro {
    padding-bottom: 12px;
  }

  .animated-heading h5 {
    font-size: 36px;
}

  .inds-tabs .slick-track .slick-slide:not(.slick-center) > div:after{
    display: none;
  }

  .inds-tabs .slick-track {
    padding: 50px 0 20px;
}

  .hero-img {
    max-width: 550px;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .widget-1 {
    width: 151px;
  }

  .widget-2 {
    width: 157px;
  }

  .get-started .col-left {
    width: calc(100% - 450px);
  }
  
  .get-started .col-right {
    max-width: 450px
  }

  .animated-heading {
    width: 400px;
  }

  .fast-track .content {
    width: calc(100% - 400px);
}

.fast-track .cta-new-section img {
  width: 90px;
}

.fast-track .cta-new-section {
  column-gap: 20px;
}
}

@media (max-width: 1024px) {
  .hero-content h1 {
    font-size: 34px;
  }
  .counter{
    flex-wrap: wrap;
    padding: 45px 35px;
  }

  .count-box{
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }

  .animt-wrap{
    flex-direction: column;
  }

  .accordion-section h2 {
    font-size: 35px;
  }

  .navigation>ul>li {
    position: relative;
    margin: 0 15px;
    font-size: 14px;
  }

  .zig-hero-content h3,
  .sec-hero h2 {
    font-size: 45px;
  }

  .about-cards-section {
    gap: 20px;
  }

  .about-card {
    width: calc(33.33% - 10px);
  }

  .cooridnate-img {
    max-width: 380px;
    padding-left: 30px;
  }

  .cooridnate-social-content {
    width: calc(100% - 380px);
  }

  .animate-section .container-new {
    padding-top: 50px;
  }


  .animated-heading h5 {
    font-size: 34px;
    padding-bottom: 20px;
  }


  .choose-socialpro h2 {
    font-size: 38px;
  }

  .hero-img {
    max-width: 550px;
    right: -29px;
  }


  .widget-2 {
    width: 151px;
  }

  .widget-1 {
    width: 154px;
  }

  .inner-feature-container {
    padding: 61px 30px 61px;
  }

  .blog {
    width: calc(50% - 20px);
    margin: 0 10px 30px;
  }

  .forms-section .accordion-wrap {
    padding: 0 20px 0 0;
  }

  .cards-wrap .card-box {
    width: calc(50% - 20px);
  }

}

@media (max-width: 991px) {
  .col-left {
    width: calc(100% - 400px);
  }

  .col-right {
    max-width: 400px;
  }

  .hero-btns a {
    font-size: 13px;
    padding: 14px 12px;
  }

  .zig-hero-content {
    width: calc(100% - 415px);
  }

  .zig-hero-img {
    max-width: 400px;
  }

  .ind-tabs button {
    width: calc(33.33% - 20px);
  }
}


@media (min-width:901px) {
  .mob-bar {
    display: none;
  }
}

@media (max-width: 900px) {

  .manage-project-wrap .zigzag:not(:last-child) {
    margin-bottom: 50px;
}

  .hero-text , .hero-image{
    width:100%
   }

  .hero-content{
    flex-direction: column-reverse;
    gap: 40px;
  }

  .hero-new:after {
    background-position: bottom;
    height: 50%;
    left: -1px;
    width: 100%;
}

.hero-btns a.outline-btn {
  background: #183157;
  border-color: #183157;
  color: #fff;
}

.hero-btns a.outline-btn:hover {
  background: #005CD1;
  border-color: #005CD1;
  color: #fff;
}

.hero-content h1 , .hero-content p{
  color: #091E42;
}

.hero-content h1 {
  max-width: 498px;
}

.hero-btns{
  flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
}

.hero-text{
  padding: 0;
}
  .zig-hero-img {
    max-width: 350px;
  }

  .zig-hero-content {
    width: calc(100% - 365px);
  }

  .zig-hero-content h3 {
    font-size: 42px;
  }

  .logos-wrap .logo-img {
    width: calc(33.33% - 20px);
  }

  .hamburger-menu {
    display: block;
  }

  .navigation ul , .head-btns{
    display: none;
  }

  .nav-login {
    width: max-content;
  }

  .mob-nav ul li:not(:last-child) {
    margin-bottom: 0;
  }

  .mob-nav {
    padding: 30px;
  }

  .mob-bar {
    transition: 0.3s all;
    border-top:1px solid #0052cc52;
    background: #fff;
    left: 0;
    position: fixed;
    transform: translateX(-400px);
    z-index: 1000;
    top: 113px;
    max-width: 326px;
    width: 100%;
    bottom: 0;
    backdrop-filter: unset !important;
    opacity: 0;
  }

  .mob-bar.active {
    transform: translateX(0);
    opacity: 1;
  }

  header.bg-active{
    backdrop-filter: none;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  .hamburger-menu:hover {
    opacity: 0.6;
  }



  .about-cards-section {
    flex-wrap: wrap;
    gap: 30px;
  }

  .about-card {
    width: 100%;
    padding: 41px 38px 29px;
  }

  .about-card img {
    max-width: 220px;
  }

  .inner-feature-container {
    padding: 43px 30px;
  }

  .featrure-tabs-buttons {
    gap: 0.8rem;
    grid-template-columns: 1fr 1fr;
    margin: 2.5rem auto 2.5rem;
    max-width: 600px;
  }

  .ind-tabs{
    max-width: 100%;
    gap: 0;
    row-gap: 20px;
  }

  .ind-tabs button{
    width: calc(50% - 20px);
  }
  .leading-social-content h3 {
    word-break: break-word;
    font-size: 44px;
  }

  .contact-slider-img img {
    max-width: 300px;
    width: 100%;
  }

  .contact-slider {
    width: calc(100% - 350px);
    padding-left: 34px;
  }

  .contact-slider .slick-slide {
    padding-bottom: 16px;
  }

  .contact-form-new {
    max-width: 350px;
  }

  .hero-main {
    padding: 170px 20px 66px
  }

  .hero-content {
    width: 100%;
  }

  .footer-flex {
    gap: 30px;
  }

  .footer-flex>a,
  .footer-flex>div {
    width: calc(50% - 35px);
    padding: 0 10px;
    flex: unset !important;
  }

  .footer-social p {
    text-align: left;
  }

  .footer-flex h4 {
    margin-top: 0;
  }

  .cooridnate-social-content h3 {
    letter-spacing: 0.03em;
    font-size: 34px;
    margin: 20px 0;
  }

  .inds-tabs h3{
    font-size: 25px;
  }
  .cooridnate-img {
    max-width: 250px;
    padding: 0;
  }

  .cooridnate-social-content a {
    font-size: 13px;
    margin-top: 20px;
    padding: 12px 18px;
  }

  .cooridnate-social-content {
    width: calc(100% - 250px);
    padding-right: 30px;
  }

  .inds-tabs .cooridnate-social-content{
    width: 100%;
    padding: 39px 20px 25px;
  }
  .cooridnate-img {
    margin-top: -16px;
  }

  .hero-new {
    padding-right: 0;
    width: 100%;
  }

  .hero-img {
    max-width: 600px;
    position: relative;
    top: 0;
    right: 0
  }

  .widget-2 {
    width: 151px;
  }

  .widget-1 {
    width: 20vw;
  }

  .widget-2 {
    width: 20vw;
  }

  .hero-content {
    width: 100%
  }

  .hero-btns a {
    padding: 13px 17px;
    font-size: 15px;
}

  .animate-section:after {
    height: 112%;
    width: 451px;
  }

  .who-we-are-wrap .cards-wrap .card-box {
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }

  .who-we-are-wrap .cards-wrap {
    justify-content: center;
    margin-bottom: -30px;
  }

  .fast-track .cta-new-section , .fast-track .box{
    flex-direction: column;
    gap: 30px;
  }

  .fast-track .box{
    align-items: center;
  }

  .animated-heading {
    width: 100%;
    padding: 0;
}

.animated-heading h5 , .choose-socialpro {
  text-align: center;

}

.choose-socialpro {
  padding: 0;
}

.fast-track .content{
  width: 100%;
}

.cta-section{
  padding: 40px 20px;
}

}

@media (max-width: 850px) {
  .col-left {
    width: 100%;
    padding: 0;
  }

  .col-right {
    max-width: 600px;
  }

  .get-started .col-right {
    max-width: 500px;
    margin: 0 auto;
}
  .forms-section .container-new {
    flex-direction: column-reverse;
    gap: 40px;
  }

  .requestdemo.forms-section .container-new{
     flex-direction: column;
  }

  .requestdemo .col-left , .requestdemo .col-right{
    max-width: 100%;
  }

  .forms-section .accordion-wrap {
    padding: 0;
  }

  .forms-section h2 , .forms-section h3{
    max-width: 100%;
  }

  .get-started .col-left {
    width: 100%;
   
  }

}

@media (max-width: 767px) {

  .loginform img{
    max-width: 200px !important;
  }

  .loginform .MuiContainer-root > div{
    min-height: 400px;
  }

  .sec-hero p {
    font-size: 13px;
    line-height: 22px;
    margin-top: 11px;
  }

  .def-txt.who-we-are p{
    text-align: left;
  }

  .blog-img a {
    height: 190px;
  }

  .blog p{
    font-size: 12px;
  }

  .sec-hero h3 {
    font-size: 11px;
    line-height: 22px;
  }

  .cooridnate-social-content ul li, .cooridnate-social-content p {
    font-size: 15px;
  }

  .counter-section {
    padding:20px 20px 50px;
}

  .inds-tabs .slick-next , .inds-tabs .slick-prev{
    display: none !important;
  }

  .inds-tabs-wrap{
    padding: 0;
  }

  .inds-tabs .cooridnate-social-content{
    background: #fff;
  }

  .question-cta{
    padding: 50px 20px;
  }
  .banner-pt {
    padding-top: 149px !important;
}

  .about-new {
    padding: 50px 0 10px;
  }

  .about-cards-section{
    padding-top: 30px;
  }

  .about-new-section .slick-slide{
    padding: 0 10px 20px;
  }
  .about-new.bg-overlay:after {
    width: 400px;
    height: 500px;
    left: -106px;
  }

  .question-cta h3 {
    font-size: 28px;
  }

  .question-cta p {
    font-size: 17px;
    margin: 26px 0 30px;
  }

  .mob-bar {
    top: 94px
  }

  .zig-hero-wrap .container-new {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .zig-hero-img img {
    margin: -49px 0 -28px 0;
    padding: 0;
  }

  .zig-hero-content h4{
    font-size: 12px;
    line-height: 17px;
  }

  .zig-hero-content p {
    font-size: 14px;
    line-height: 20px;
  }

  .zig-hero-content {
    width: 100%;
  }

  .zig-hero-img {
    margin: 0 auto;
  }

  .zig-hero-content h3 {
    font-size: 40px;
  }

  .zig-hero-content p {
    margin: 19px 0 0;
    max-width: 100%;
  }

  .bg-overlay:after,
  .bg-overlay-right:after {
    width: 679px;
    left: -182px;
  }

  .bg-overlay-right:after {
    top: 0;
    bottom: unset;
  }

  .logos-wrapper {
    padding: 50px 10px
  }

  .logos-wrapper h3 {
    margin: 0 0 21px;
    font-size: 14px;
  }

  .accordion-title>div {
    font-size: 16px;
    line-height: 26px;
  }

  .contact-section {
    flex-direction: column-reverse;
    padding: 50px 15px;
  }

  .contact-slider {
    width: 100%;
    padding: 0 0 50px;
  }

  .contact-form-new {
    max-width: 600px;
  }

  .contact-slider .slick-slide {
    padding-bottom: 10px;
  }

  .contact-form-new form {
    max-width: 100%;
  }

  .site-logo {
    display: block;
    max-width: 180px;
  }

  .navigation {
    padding: 24px 0;
  }

  .widget-1,
  .widget-2 {
    width: 21vw;
  }

  .inner-feature-container {
    padding: 30px 44px 40px;
  }

  .cooridnate-social {
    flex-direction: column-reverse;
  }

  .cooridnate-social-content {
    width: 100%;
    padding: 10px 0 0;
  }

  .cooridnate-img {
    max-width: 300px;
    margin: 0 auto;
  }

  .cooridnate-social-content h3 {
    max-width: 100%;
    font-size: 23px;
    margin: 10px 0;
    line-height: 28px;
  }

  .cooridnate-social-content .discover,
  .contact-slider-content .discover {
    margin-right: 12px;
  }

  .feature-tab-section {
    padding: 50px 20px;
  }

  .cta-new-section a {
    font-size: 14px;
    padding: 15px 23px;
  }

  .form-control-new {
    margin-bottom: 18px;
  }

  .contact-form-btn {
    margin-top: 10px;
  }

  .hero-content h1 {
    font-size: 34px;
  }

  .feature-tagline,
  .about-tagline,
  .cta-new-section h3,
  .definitions-section h3,
  .leading-social-content h3,
  .contact-slider-content h3 {
    font-size: 27px;
    line-height: 33px;
  }

  .request-demo-section h3 {
    font-size: 32px;
    margin: 0 0 20px;
  }

  .forms-section {
    padding: 50px 20px;
  }

  .about-card {
    padding: 30px 25px 20px;
  }

  .contact-form-new h5 {
    font-size: 27px;
    margin: 20px 0 30px;
  }

  .inner-feature-container {
    border-radius: 17px;
  }

  .cooridnate-social-content ul li {
    font-size: 13px;
  }

  .sec-hero p {
    margin-top: 20px;
  }

  .sec-hero,
  .accordion-wrap {
    padding: 50px 20px;
  }

  .blog-section {
    padding: 50px 20px 30px;
  }

  .sec-hero {
    min-height: 250px;
  }

  .cards-wrapper {
    padding: 50px 20px 30px;
  }

  .blog-detail h1 {
    font-size: 20px;
    margin: 0;
  }

  .blog-detail h4 {
    font-size: 16px;
  }

  .blog-detail .blog-det-img {
    margin: 30px 0 25px;
    display: block
  }

  .forms-section h2 {
    font-size: 38px;
    margin: 20px 0;
  }

  .accordion-section h2 {
    font-size: 32px;
  }

  .support-section .accordion-wrap {
    margin-top: 20px;
  }

  .accordion-section {
    padding: 50px 20px;
  }

  .who-we-are-wrap h3 {
    margin-bottom: 34px;
  }

  .slider-logo-wrap:after,
  .slider-logo-wrap:before {
    width: 66px;
  }

  .blog-detail .text p {
    font-size: 15px;
    line-height: 1.5;
  }

  .who-we-are-wrap.cards-wrapper {
    padding: 35px 0 0;
}

.def-txt img {
  margin: 38px auto 20px;
}

.animate-section {
  padding: 20px;
}

.bg-overlay:after, .overlay-right:after, .bg-overlay-dark:after, footer:after{
  width: 460px;
}

.slider-logo {
  padding: 0 20px 30px;
}

.inds-tabs .cooridnate-social-content p{
  max-width: 100%;
  margin-top: 20px;
}

.inds-tabs .slick-track{
  padding: 50px 0 0;
}

.mob-nav a.nav-login{
  color: #005CD1;
}


.mobile-hero{
  display: block;
}

.api-section .cta-new-section .box{
  text-align: center
 }
 
 .cta-new-section p{
  margin-top: 20px
 }
 
 .cta-new-section img {
     width: 170px;
 }

 .api-section .cta-new-section img {
  margin: -13px 0;
}
 
 .api-section .cta-new-section{
      flex-direction: column;
 }
 
 .api-section .cta-wrapper {
   padding: 50px 23px
 }

 .fast-track .cta-new-section {
   flex-direction: column;
   gap: 30px
 }

 .fast-track .box {
   flex-direction: column
 }

 .fast-track .content,
 .fast-track .content p {
   text-align: center;
   max-width: 100%
 }

}

@media (max-width: 730px) {

  .animated-heading:after,
  .animated-heading:before {
    width: 100%;
    height: 40px;
  }

  .animated-heading:after {
    top: 16px;
  }

  .blog {
    width: 100%;
    padding: 0 20px 30px;
    margin: 0 0 20px;
  }

  .blogs-wrap {
    margin: 0;
  }
}

@media (max-width: 640px) {

  .count-box {
  width: 100%;
  margin-bottom: 20px;
  }

  .counter {
  padding: 35px 30px 20px;
  }

  .count-box div.count , .count-box > div > span {
  font-size: 35px;
  }

  .footer-flex {
    padding: 50px 30px;
  }

  .definitions-section .def-txt{
    padding: 0 10px;
  }

  .who-we-are-wrap .cards-wrap .card-box {
    width: 100%;
    margin: 0 0 30px;
  }

  .logos-wrap {
    margin-bottom: -20px;
  }

  .logos-wrap .logo-img {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }


  .accordion-section h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .accordion-title {
    padding: 10px 9px 10px 5px;
  }

  .accordion-content {
    padding: 0 5px;
  }

  .accordion-content p {
    font-size: 13px;
  }


  .zig-hero-content h3,
  .sec-hero h2 {
    font-size: 28px;
  }

  .choose-socialpro h2 {
    font-size: 28px;
  }

  .animated-heading h5 {
    font-size: 30px;
    padding-bottom: 10px;
    line-height: 35px;
  }

  .footer-logo-new {
    width: 180px;
  }

  .hero-content h1 {
    font-size: 28px;
    max-width: 408px;
  }

  .hero-content p {
    margin: 20px 0;
  }

  .forms-section h2 {
    font-size: 28px;
    margin: 20px 0 22px;
  }


}

@media (max-width: 600px) {

  .hero-new:after {
    height: 40%;
  }

  h1,
  h2,
  h3,
  h4 {
    word-break: break-word;
  }

  .cooridnate-img {
    max-width: 80%;
    padding: 0;
  }

  .contact-slider-content h3 {
    margin: 15px auto;
    max-width: 100%;
  }


  .hero-main {
    gap: 51px;
    padding-top: 150px;
  }

  .cards-wrap .card-box {
    width: 100%;
    margin: 0 0 20px;
  }

 .about-title , .feature-title ,.cooridnate-social-content h4 , .cta-new-section h4{
    font-size: 11px;
  }

  .feature-txt-new ,.cta-new-section p , .col-left ul li{
    font-size: 13px;
  }

  .cta-new-section p {
    margin-top: 20px;
  }

  .form-label-new{
    font-size: 14px;
  }

  body p{
    font-size: 14px;

  }

  .forms-section h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
}

@media (max-width: 479px) {

  
  .footer-links ul li a {
    margin: 0 0 2px;
  }

  .footer-flex h4 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .desk-hide {
    display: block;
    margin-top: 10px;
  }

  .mob-hide {
    display: none;
  }

  .social-links {
    justify-content: flex-start;
  }

  .slider-logo-wrap:after,
  .slider-logo-wrap:before {
    width: 50px;
  }

  .choose-socialpro h2{
    font-size: 26px;
  }
  

  .animated-heading h5 {
    font-size: 20px;
    line-height: 30px;
  }

  .animate-section .container-new{
    padding: 35px 25px 12px;
  }

  .footer-flex {
    gap: 0;
  }

  .footer-flex>a,
  .footer-flex>div {
    width: 100%;
    padding: 0 0 20px;
  }

  .footer-flex .footer-links:first-child{
    padding-bottom: 30px ;
  }

  .footer-flex>div:last-child {
    padding-bottom: 0;
  }

  .social-links {
    justify-content: flex-start;
  }

  .footer-social p {
    text-align: left;
  }

  .footer-logo-new {
    margin: 0;
  }

  .footer-social {
    margin-top: 22px;
  }

  .social-links a img {
    width: 30px;
  }

  .social-links {
    gap: 10px;
  }

  .inner-feature-container {
    padding: 29px 30px 47px;
  }

  .cooridnate-social-content .discover,
  .cooridnate-social-content a,
  .contact-slider-content .discover,
  .contact-slider-content .request {
    display: block;
    text-align: center;
    width: 100%;
  }

  .featrure-tabs-buttons button,
  .featrure-tabs-buttons .active-tab,
  .featrure-tabs-buttons button:hover {
    border-radius: 10px;
    padding: 14px 14px;
    font-size: 14px;
  }

  .contact-slider-content a {
    margin-top: 15px;
  }

  .contact-slider-content .discover {
    margin-top: 20px;
  }

  .hero-btns div {
    max-width: 170px;
    margin: 0 auto;
  }

  .hero-btns a {
    padding: 13px 19px;
    font-size: 14px;
  }

  .hero-btns div a {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .slider-logo img {
    max-width: 64%;
  }

  .logo-img img {
    max-width:98px;
    max-height: auto;
  }

  footer:after {
    left: -142px;
    top: -76px;
    background-size: contain;
  }
}

/* Animation  */
@keyframes slideY-new {

  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@media (max-width:1175px){

  .animated-heading h5 , .choose-socialpro h2{
      font-size: 29px;
      line-height: 23px;
   }
   
  }
  
  @media (max-width:1070px){
   
   .animate-section .container-new{
    padding-top: 60px
   }
   
   .animt-wrap{
    flex-direction: column
   }
   
  }

  @media (max-width:767px){

    .slider-logo h3 {
      margin-bottom: 11px;
      font-size: 11px;
    }


   .animated-heading .slick-track .slick-slide.slick-center h5{
    font-size: 24px !important;
    line-height: 24px !important;
   }

   .animate-section .container-new{
    padding-top: 50px;
   }
  
  }

  @media (max-width:520px){

 
    /* .animated-heading h5 {
      font-size: 16px;
      line-height: 16px;
   } */



   .animate-section .container-new{
    padding: 35px 20px 12px;
   }

  
  }


  @media (max-width:375px){

     .cooridnate-social-content h3{
      font-size: 20px;
     }

     .inds-tabs .slick-slide img {
      width: 60px;
      height: 60px;
     }

      .inds-tabs .cooridnate-social-content p {
      font-size: 13px;
    }

    .animated-heading h5 {
      font-size: 22px;
      line-height: 22px;
  }

  .choose-socialpro h2{
    font-size: 24px;
    line-height: 24px;
  }

  .hero-content h1 {
    font-size: 22px;
    line-height: 30px;
   }
  }

  .btn{
    border: 1px solid #F2F2F2;
    border-radius: 9px;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    margin-top: 18px;
    padding: 13px 28px;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    color: #183157;
    background: #F2F2F2;
    font-family: "Montserrat";
 }
 
 .btn:hover {
    background-color: #111653;
    color: white;
    border-color: #111653;
 }

 /* .btn.btn-grey {
   background: #F2F2F2;
   border-color: #F2F2F2;
   color: #000
 }

 .btn.btn-grey:hover {
   background: rgb(0, 99, 198);
   border-color: rgb(0, 99, 198);
   color: #fff;
 } */


 .manage-project-wrap {
  margin-top: 80px
 } 

 .manage-project-wrap .zigzag:not(:last-child){
  margin-bottom:60px;
 }

 .zigzag{
  display: flex;
  gap:70px;
  justify-content: space-between;

 }

 .zig-content {
  padding: 30px 0;
 }
 
 .zig-content , .zig-img{
  width:calc(50% - 35px)
 }
 
 .zig-img img{
  width: 100%;
  margin: -63px 0;
  max-width: 550px;
 }

 .zigzag.zig-reverse{
  flex-direction: row-reverse
 }


.zig-img {
transition: 0.3s all;
}

.zig-img:hover{
transform: scale(1.05);
}

 @media (max-width:900px){
  .zigzag {
    gap: 10px;
  }

  .zig-content, .zig-img {
    width: calc(50% - 20px);
  }
  .zig-img img {
    margin: -16px 0;
}

.zigzag h3{
  font-size: 34px;
}
 }

 @media (max-width:767px){
  .zigzag , .zigzag.zig-reverse{
    flex-direction: column-reverse;
  }

  .zig-content, .zig-img {
    width: 100%;
  }

  .zig-content{
    padding: 0;
  }

  .zig-img img {
    margin: -50px auto -10px;
    max-width: 400px;
  }

  .manage-project-wrap {
    margin-top: 60px;
  }
  .zigzag h3 {
    font-size: 28px;
    max-width: 400px;
    margin: 10px 0 0;
}

.zigzag p {
  font-size: 15px;
 }
  
 }

 /* @media (max-width:479px){
  .zigzag p {
    font-size: 15px;
   }
 } */