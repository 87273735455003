html {
  overflow-y: auto;
  scroll-behavior: smooth;
}
.body {
  font-weight: 600;
  scrollbar-width: thin;
}

main {
  margin-right: 0px !important;
}

input:disabled {
  cursor: default;
  /* background-color: rgba(239, 239, 239, 0.3) !important; */
  color: rgb(84, 84, 84) !important;
  border-color: rgba(118, 118, 118, 0.3);
}
/* .aws-btn {
  margin: auto !important;
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 12px;
  --button-horizontal-padding: 12px;
  --button-raise-level: 5px;
  --button-hover-pressure: 2;
  --transform-speed: 0.1s;
  --button-primary-color: #008ce6;
  --button-primary-color-dark: #0065a3;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #099efb;
  --button-primary-border: 0px solid #ffffff;
  --button-secondary-color: #fffc6c;
  --button-secondary-color-dark: #b9b500;
  --button-secondary-color-light: #6c6a00;
  --button-secondary-color-hover: #fffb3e;
  --button-secondary-border: none;
  --button-anchor-color: #f3c8ad;
  --button-anchor-color-dark: #734922;
  --button-anchor-color-light: #4c3016;
  --button-anchor-color-hover: #f1bfa0;
  --button-anchor-border: 1px solid #8c633c;
} */

.total-row {
  background-color: #f0f0f0; /* Light grey background */
  font-weight: bold; /* Make the text bold */
}

.ag-center-cols-viewport {
  min-height: 42px !important;
}

.ag-header-cell-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
