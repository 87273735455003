body .centered-table-header .ag-header-cell-label .ag-header-cell-text {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

body .right-table-header .ag-header-cell-label .ag-header-cell-text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}

body .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: 0;
}

body .ag-header-container .ag-header-cell-text {
  color: #71767f;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

body .ag-cell-label-container {
  padding: 0 12px;
  background-color: white;
  font-size: 12px;
}

body .ag-header-cell-comp-wrapper {
  background-color: white;
  justify-content: center;
  width: "auto";
}

body .ag-header {
  border-bottom: 1px solid #e9eaeb !important;
}

body .ag-header-cell {
  padding: 0;
  border: 1px solid white;
}

body .ag-ltr .ag-cell {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #535862;
  font-weight: 400;
  border: 1px solid rgb(255, 255, 255) !important;
  font-family: "Inter", sans-serif;
}

body .ag-cell-wrapper{
  width: 100%
}

body .center-ag-cell{
  display: flex;
  justify-content: center;
  align-items: center;
}


body .ag-row {
  border-bottom: 1px solid #e9eaeb !important;
  padding: 0 11px !important;
  /* transform: none !important; */
  /* position: static; */
  display: flex;
  align-items: center;
}

body .ag-row:hover {
  background: none !important;
}

body .ag-row .ag-column-first {
  font-weight: 500;
  color: #181d27;
}

body .ag-row-hover::before {
  display: none !important;
}

body .ag-root-wrapper {
  border: 0;
}

body .ag-center-cols-container .ag-row:last-child {
  border-bottom: 0 !important;
}



.editor-wrapper {
  border-radius: 6px;
}

.ql-toolbar {
  border: none !important; /* Removes the border around the toolbar */
  padding: 2px !important;
}

.actionlist .MuiMenu-paper ul li:hover p, .actionlist .MuiMenu-paper ul li:hover svg path{
  color: #155EEF;
  stroke:#155EEF
 }
 
 .actionlist  .MuiMenu-paper ul li{
  transition: 0.2s all
 }